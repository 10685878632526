#cta {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#cta p {
    color: white;
    font-weight: bold;
}

#cta #ctaWpp {
    text-align: left;
} 

#cta #ctaWpp img {
    width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #cta p {
        text-align: center;
        font-size: 1.4rem;
    }
    
    #cta #ctaWpp {
        text-align: center;
    } 

    #cta #ctaWpp img {
        width: 70%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}