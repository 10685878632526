#footer .footer1 {
    background-color: #262D2F;
}

#footer .footer1 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer .footer1 .container .left {
    width: 30%;
}

#footer .footer1 .container .left img {
    width: 60%;
}

#footer .footer1 .container .middle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .middle .item {
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .middle .title_menu,
#footer .footer1 .container .right .title_menu {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-bottom: .5rem;
    color: white;
}

#footer .footer1 .container .middle .item .line_1,
#footer .footer1 .container .middle .item .line_2 {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: #848A8D;
}

#footer .footer1 .container .middle .item .line_1 img,
#footer .footer1 .container .middle .item .line_2 img {
    width: 5%;
    height: 70%;
}

#footer .footer1 .container .middle .item .line_1 a,
#footer .footer1 .container .middle .item .line_2 a {
    color: inherit;
}

#footer .footer1 .container .middle .item img {
    filter: invert(100%) sepia(0%) saturate(7458%) hue-rotate(326deg) brightness(99%) contrast(109%);
}

#footer .footer1 .container .middle .item .line_unique {
    width: 90%;
    color: #848A8D;
}

#footer .footer1 .container .right .title_menu {
    margin-bottom: 1rem;
}

#footer .footer1 .container .right .maprouter {
    position: relative;
    text-align: right;
    width: 100%;
    margin-bottom: 2rem;
}

#footer .footer1 .container .right .maprouter .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    transform: translateY(0px);
}

#footer .footer1 .container .right .maprouter .gmap_canvas .gmap_iframe {
    height: 250px !important;
    border-radius: 5px;
}

#footer .footer1 .container .right .gps {
    text-align: left;
}

#footer .footer1 .container .right .gps img {
    width: 60%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        flex-direction: column;
        gap: 2rem;
    }

    #footer .footer1 .container .left {
        width: 100%;
        border-bottom: 1px solid white;
        padding-bottom: 1rem;
    }

    #footer .footer1 .container .left img {
        width: 40%;
    }

    #footer .footer1 .container .middle {
        flex-direction: row;
        order: 2;
    }

    #footer .footer1 .container .right .maprouter {
        width: 500px;
    }

    #footer .footer1 .container .right .maprouter .gmap_canvas .gmap_iframe {
        height: 400px !important;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: .5rem;
    }

    #footer .footer2 .container .text {
        border-bottom: 1px solid black;
        padding-bottom: .5rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .right .maprouter {
        width: 350px;
    }

    #footer .footer1 .container .right .maprouter .gmap_canvas .gmap_iframe {
        height: 300px !important;
    }

    #footer .footer1 .container .middle {
        flex-direction: column;
    }

    #footer .footer1 .container .middle .item .line_1 img,
    #footer .footer1 .container .middle .item .line_2 img {
        width: 7%;
    }
}