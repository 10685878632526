#header .header1 {
    background: url("../../assets/BANNER_TOPO.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

#header .header1 .container .title {
    width: 45%;
}

#header .header1 .container .title span {
    color: white;
}

#header .header2 .container {
    display: flex;
    justify-content: center;
    gap: 4rem;
}

#header .header2 .container .left .title,
#header .header2 .container .left .text-light {
    display: none;
}

#header .header2 .container .left figure {
    width: 80%;
}

#header .header2 .container .left figure img {
    border-radius: 5px;
}

#header .header2 .container .right,
#header .header2 .container .right ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#header .header2 .container .right ul li {
    display: flex;
    align-items: center;
    gap: .5rem;
}

#header .header2 .container .right ul li img {
    width: 5%;
}

#header .header2 .container .right #cta2 {
    margin-top: 2rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/BANNER_TOPO.png") no-repeat center 60%;
        background-size: cover;
        height: 90vh;
    }

    #header .header1 .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
    }

    #header .header1 .container .title {
        width: 100%;
        font-size: 2.5rem;
    }

    #header .header1 .container #cta {
        align-self: center;
        margin-top: auto;
    }

    #header .header2 .container {
        flex-direction: column;
        gap: 1rem;
    }

    #header .header2 .container .left .title,
    #header .header2 .container .left .text-light {
        display: block;
    }

    #header .header2 .container .left .text-light {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    #header .header2 .container .left figure {
        width: 100%;
    }

    #header .header2 .container .right,
    #header .header2 .container .right ul {
        gap: .5rem;
    }

    #header .header2 .container .right .title,
    #header .header2 .container .right .text-light {
        display: none;
    }

    #header .header2 .container .right ul li {
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    #header .header2 .container .right ul li img {
        width: 3%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}