#form {
    color: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    width: 60%;
    color: #393939;
    margin: 0 auto;
}

#form .title {
    text-align: center;
    margin-bottom: 1rem;
}

#form .form-subtitle {
    font-weight: 400;
    text-align: center;
}

#form .form-div1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

#form .form-div1 label {
    margin-top: .3rem;
}

#form .form-div1 span {
    color: red;
}

#form .form-div1 input,
#form .form-div1 textarea {
    border-radius: 5px;
    padding: .5rem 5px;
    background-color: #F7FAFD;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 100%;
}

#form .form-div2 {
    display: flex;
    justify-content: space-between;
}

#form .form-div2 .checkbox {
    margin-top: 1rem;
}

#form .form-div2 .checkbox label {
    margin-left: .5rem;
}

#form .form-div2 button {
    background-color: transparent;
    cursor: pointer;
    margin-top: .5rem;
    width: 40%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #form .form-div1 input,
    #form .form-div1 textarea {
        width: 100%;
    }

    #form .form-div1 .row {
        gap: 1rem;
    }

    #form .form-div1 .row input {
        width: 45%;
    }

    #form .form-div2 .checkbox {
        text-align: center;
    }

    #form .form-div1 .row .top label {
        margin-right: 6.5rem;
    }

    #form .form-div2 {
        display: block;
    }

    #form .form-div2 .checkbox {
        text-align: left;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #form {
        width: 100%;
        margin-left: 0;
    }

    #form .form-div2 button {
        width: 100%;
    }
}