#cta2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#cta2 p {
    color: black;
    font-weight: bold;
}

#cta2 #ctaWpp {
    text-align: left;
} 

#cta2 #ctaWpp img {
    width: 55%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #cta2 p {
        text-align: center;
        font-size: 1.3rem;
    }

    #cta2 #ctaWpp {
        text-align: center;
    }

    #cta2 #ctaWpp img {
        width: 35%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #cta2 #ctaWpp img {
        width: 60%;
    }
}