#about .about1 {
  background-color: #F1F8FF;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about1 .container .title {
  text-align: center;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-areas: "a b c" "d e e";
  gap: 2rem;
}

#about .about1 .container .gallery .item1 {
  grid-area: a;
}

#about .about1 .container .gallery .item2 {
  grid-area: b;
}

#about .about1 .container .gallery .item3 {
  grid-area: c;
}

#about .about1 .container .gallery .item4 {
  grid-area: d;
}

#about .about1 .container .gallery .item5 {
  grid-area: e;
}

#about .about1 .container .gallery .item .cta {
  margin-top: -6rem;
}

#about .about1 .container .gallery .item .cta img {
  width: 60%;
}

#about .about1 .container .gallery .item.item5 .cta img {
  width: 30%;
}

#about .about2 {
  display: flex;
}

#about .about2 .left {
  width: 100%;
}

#about .about2 .left .title {
  display: none;
}

#about .about2 .left figure img {
  display: block;
  width: 100%;
}

#about .about2 .right {
  background-color: #333438;
  padding: 1rem 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  width: 100%;
}

#about .about2 .right .title {
  color: #7BB24D;
  font-size: 3rem;
}

#about .about2 .right #cta2 p {
  color: white;
}

#about .about2 .right #cta2 #ctaWpp img {
  width: 40%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .title {
    font-size: 3rem;
  }

  #about .about1 .container .gallery {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #about .about1 .container .gallery .item .cta {
    margin-top: -9rem;
  }

  #about .about1 .container .gallery .item.item5 .cta img {
    width: 60%;
  }

  #about .about2 {
    flex-direction: column;
    background-color: #333438;
    gap: 2rem;
  }

  #about .about2 .left .title {
    display: block;
    color: #7BB24D;
    font-size: 3rem;
    text-align: center;
    padding: 1rem 0;
  }

  #about .about2 .left figure img {
    width: 100%;
  }

  #about .about2 .right {
    background-color: transparent;
    padding: 1rem 2rem;
    padding-top: 0;
  }

  #about .about2 .right .title {
    display: none;
  }

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .gallery .item .cta {
    margin-top: -5rem;
  }

  #about .about2 .right #cta2 #ctaWpp img {
    width: 70%;
  }
}