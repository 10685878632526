#menu .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .icon {
    position: absolute;
    left: 2rem;
    width: fit-content;
    height: 44px;
    cursor: pointer;
    display: none;
    z-index: 4;
}

#menu .container .hamburguer {
    top: 50%;
    left: 10%;
    width: 3rem;
    height: 6px;
    background-color: #D9D9D9;
    position: absolute;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 400ms ease-in-out;
}

#menu .container .hamburguer::before {
    top: -12px;
    content: '';
    position: absolute;
    width: 3rem;
    height: 6px;
    background: #D9D9D9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 400ms ease-in-out;
}

#menu .container .hamburguer::after {
    top: 12px;
    content: '';
    position: absolute;
    width: 3rem;
    height: 6px;
    background: #D9D9D9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 400ms ease-in-out;
}

#menu .container .left figure {
    text-align: left;
}

#menu .container .left figure img {
    width: 60%;
}

#menu .container .right {
    display: flex;
}

#menu .container .right .item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#menu .container .right .title_menu {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
}

#menu .container .right .item .line_1,
#menu .container .right .item .line_2 {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: #848A8D;
}

#menu .container .right .item .line_1 img,
#menu .container .right .item .line_2 img {
    width: 6%;
    height: 70%;
}

#menu .container .right .item .line_1 a,
#menu .container .right .item .line_2 a {
    color: inherit;
}

#menu .container .right .item .line_unique {
    width: 90%;
    color: #848A8D;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        flex-direction: column;
    }

    #menu .container .icon {
        display: block;
    }

    #menu .container .left figure {
        text-align: center;
    }

    #menu .container .left figure img {
        width: 100%;
    }

    #menu .container .right.menuOpen {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        left: 2rem;
        top: 7vh;
        z-index: 2;
        background-color: white;
        padding: 1rem;
        border-radius: 10px;
    }

    #menu .container .right.menuClose {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}