#success {
    background: url("../../assets/BANNER_TOPO.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: center;
}

#success .container .title {
    color: white;
    width: 50%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {

}